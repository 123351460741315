<template>
  <v-row class="my-0 mx-auto">
    <v-col cols="12" class="pa-0 d-flex align-center justify-center">
      <v-card elevation="0" max-width="340" width="100%" rounded="lg" class="mx-auto my-4 px-md-0 px-2 mt-md-16 mt-7">
        <v-card width="72" height="72" tile flat class="secondary rounded-circle mx-auto d-flex align-center justify-center mb-7">
          <img src="@/assets/icon/lock.svg" height="32" alt="lock" />
        </v-card>
        <div class="text-center h2 mb-2">Password recovering</div>
        <div v-if="action" class="text-center">
          <div class="h8 text--text">We have sent the recovery link on your email.</div>
          <div class="h8 text--text mb-8">You can close this page</div>
          <v-btn text to="/sign-in">Back to Log In Page</v-btn>
        </div>
        <div v-else>
          <div class="h8 text--text mb-4">
            Enter the email address that's already linked to your account. There will be sent a link to recover the password.
          </div>
          <v-form @submit.prevent="forgot" ref="form" lazy-validation>
            <div class="h10">Email</div>
            <v-text-field
              class="field44"
              v-model="email"
              :error-messages="emailErrors"
              placeholder="Enter email"
              outlined
              dense
              required
              clearable
              clear-icon="mdi-close-circle"
              color="secondary"
              height="44"
            ></v-text-field>
            <v-btn depressed type="submit" block large color="primary">SEND</v-btn>
          </v-form>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      email: '',
      action: false,
      error: [],
    };
  },
  methods: {
    async forgot() {
      this.error = [];
      await this.$store
        .dispatch('requestPassword', { email: this.email })
        .then(() => {
          this.action = true;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'account__not_found') && errors.push('The account with the provided email is not found');
      this.error.find((item) => item == 'action__limit_exceeded') &&
        errors.push('Sorry, but password reset request is not available now. Please try again later');
      return errors;
    },
  },
};
</script>
